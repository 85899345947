@font-face {
  font-family: 'WIVFont';
  src: local('WIVFont'),
    url('./Fonts/WIVFont.ttf') format('woff2')
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202020;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6a6a6a;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  background-repeat: no-repeat;
  overflow: hidden;
  width: var(--container-width-lg);
  margin: 0 auto;
}

.dynamic-container {
  width: 95%;
  height: 100%;
  position: absolute;
  left: 5%;
}

.dynamic-container-right {
  width: 95%;
  height: fit-content;
  position: absolute;
  right: 5%;
}

.dynamic-container-box {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  top: 50%;
  transform: translateY(-50%);
}

.dynamic-container-box-right {
  display: flex;
  flex-direction: column;
  float: right;
  position: relative;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.banner1 {
  width: 100%;
  height: 100vh;
  background: rgb(2, 0, 36);
  background: #1A191B;
  background-size: auto;
  border-bottom: 30px solid #009900;
}

.videooverlay {
  position: absolute;
  bottom: 25%;
  left: 12.5%;
  right: 12.5%;
  overflow: hidden;

}

.videoTag {
  z-index: 100;
  height: 100vh;
  object-fit: cover;

}