.Footer-head {
  background-color: #FAC300;
  height: 10px;
}

.Footer {
  background-color: black;
  font-family: WIVFont;
  color: white;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.foot-logo {
  height: auto;
  max-width: 30%;
  align-items: right;
}

.foot-logo-mobile {
  height: auto;
  max-width: 60%;
  align-items: right;
}

.Social {
  -webkit-filter: invert(1);
  filter: invert(1);
  height: auto;
  max-width: 5%;
  margin-right: 2rem;
  float: right;
}

.KFP {
  height: auto;
  max-width: 8%;
  margin-right: 2rem;
  margin-left: 2rem;
  border-radius: 15%;
}

@media (max-width: 480px) {
  .Social {
    max-width: 45%;
    display: flex;
    justify-content: center;
    float: 0;
    margin-right: 1.5rem;

  }

  .Footer {
    padding-left: 0rem;
  }

  .foot-logo {
    max-width: 100%;
  }
}

.FooterTags {
  margin-right: 10px;
  padding-right: 10px;
  font-size: 20px;
}