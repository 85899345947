@font-face {
  font-family: 'WIVFont';
  src: local('WIVFont'),
    url('./Fonts/WIVFont.ttf') format('woff2')
}


.headline-small {

  margin-top: 100px;
  margin-bottom: 100px;
}

.container {
  background-repeat: no-repeat;
  overflow: hidden;

}

.headline {
  color: #fff;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  z-index: 30000;
}

.Contact {
  background-color: #009900;
  border-radius: 42px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}


.FilterButton1 {
  background-color: #009900;
  border-radius: 42px;
  display: flex;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}

.FilterButton1:hover {
  background-color: #5cbf2a;
}

.FilterButton1:active {
  position: relative;
}


.FilterButton2 {
  background-color: #5A595B;
  border-radius: 42px;
  display: flex;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}

.FilterButton2:hover {
  background-color: #727272;
}

.FilterButton2:active {
  position: relative;
}


.FilterButton3 {
  background-color: #A2A297;
  border-radius: 42px;
  display: flex;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 25px;
  text-decoration: none;
}

.FilterButton3:hover {
  background-color: #bcbcb3;
}

.FilterButton3:active {
  position: relative;
}

.contentbox {
  font-size: xx-large;
  color: white;
  margin-top: -40px;
  font-weight: 200;
}

.Contentblock {
  font-weight: 200;
  font-size: 30px;
  float: left;
  color: white;
  margin-left: 5%;
  margin-right: 20%;
  margin-top: -70px;
  background-color: #2A2A2B;
  padding: 30px;
}

.Colorbars {
  width: 40px;
  height: auto;
  margin-bottom: -10px;
  margin-left: 15px;
}

.headline-small-mobile {
  font-size: 25px;
  font-weight: 400;
  color: white;
  margin-left: 5%;
  margin-bottom: 10%;
}

.secondrow-mobile {
  font-weight: 100;
  font-size: 25px;
  float: left;
  color: white;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: -3%;
}