.markercluster-map {
    height: 50vh;
    width: 100%;
  }

  .Datenschutz {
    font-family: WIVFont;
    color: #fff;
    background-color: #1A191B;
    padding-left: 10%;
    padding-right: 10%;
  }

  .banner {
    width: auto;
    height: 100%;
    background: rgb(2, 0, 36);
    background: #1A191B;
    background-size: auto;
  
  }
  .container {
    background-repeat: no-repeat;
    overflow: hidden;
  }
  
  .leaflet-div-icon {
    background-color: black;
  }
  