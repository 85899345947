.NavbarPosition {
    z-index: 500000;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: white;
    position: sticky;
    top: 0;
}

nav .title {
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;
    padding-left: 0%;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:hover {
    background-color: #FAC300;
    color: white
}

nav .menu {
    display: none;
    position: relative;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav ul li {
    position: relative;
    list-style: none;
    font-family: "WIVFont";
    color: #fff;
}

nav ul li a {
    position: relative;
    list-style: none;
    font-family: "WIVFont";
    color: #fff;
}


.nav-logo {
    height: 56px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.nav-logo-mobile {
    height: auto;
    width: 40%;
    margin-top: 5%;
    margin-bottom: 5px;

}


.NavbarPosition {
    z-index: 500000;
    background-color: #2B2A2A;
}

.Social {
    color: "000000"
}